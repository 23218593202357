h3 {
  margin-bottom: 0.25rem;
}

p ~ h3,
p ~ h4 {
  margin-top: 1.5rem;
}

body {
  font-size: 14px;
  text-align: justify;
}
