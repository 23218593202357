@import 'theme';

body {
  width: 100dvw;
  height: 100dvh;

  background-color: $theme-bg;
  color: $theme-neutral-dark;
  display: flex;
  flex-direction: column;

  header {
    background-color: $theme-neutral-dark;
    color: $theme-neutral-light;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 150vh;

    & > a {
      all: unset;
      max-height: 50vh;
      cursor: pointer;
      display: flex;
      width: 50%;
      max-width: 768px;
      margin: auto;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      margin: 1rem;
      padding: 0rem;

      .header-svg {
        width: 50%;
      }

      &:focus {
        outline: orange 2px auto;
      }

      @media (max-width: 768px) {
        .header-svg {
          width: 100%;
        }
      }
    }

    nav {
      width: 100%;
      max-width: 768px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      padding: 0 1rem 0.5rem 1rem;
      gap: 2rem;
      & > a {
        display: inline-block;
        all: unset;
        cursor: pointer;
        padding: 0 0.25rem 0.25rem 0.25rem;
        color: $theme-neutral-light;
        border-bottom: 1px solid $theme-neutral-light;
        text-transform: uppercase;
        &.current {
          color: $theme-primary;
          border-bottom: 2px solid $theme-primary;
        }
        &:focus {
          outline: orange 2px auto;
        }
      }
    }
  }

  main {
    flex: 1;
    width: 100%;
    max-width: 768px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  footer {
    background-color: $theme-neutral-dark;
    color: $theme-neutral-light;
    padding: 0.5rem 2rem 0.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid $theme-neutral-dark;
    justify-content: space-between;
    gap: 1rem;
  }
}
