@import '../theme';

.tabs {
  width: 100%;

  .tab-buttons {
    gap: 1rem;
    display: flex;
    flex-direction: row;
    z-index: -100;
    width: 100%;
    margin-bottom: 1rem;

    & > button {
      all: unset;
      cursor: pointer;
      border: none;
      padding: 0 0.25rem 0.25rem 0.25rem;
      margin: 0;
      color: $theme-neutral-dark;
      border-bottom: 1px solid $theme-neutral-dark;

      &:focus {
        outline: orange 2px auto;
      }

      &:disabled {
        color: $theme-primary;
        border-bottom: 2px solid $theme-primary;
      }
    }
  }

  .tab-content {
    background: $theme-bg;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}
