.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow-y: auto;

  @media (max-width: 768px) {
    font-size: 0.75em;
    line-height: 1.2;
  }
}

.modal form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  max-height: 75vh;
  overflow-y: auto;
}

.button-container {
  display: flex;
  justify-content: space-between;
}
