@use 'sass:color';

$theme-bg: #fff5f0;
$theme-primary: #35b6e0;
$theme-secondary: #288b7d;
$theme-neutral-dark: #101928;
$theme-neutral-light: #cec3be;

button {
  all: unset;
  cursor: pointer;
  align-self: center;
  max-width: 25%;
  $background: $theme-primary;
  &.secondary {
    $background: $theme-secondary;
  }

  background-color: $background;

  border: 1px solid color.scale($background, $lightness: -15%);
  border-radius: 6px;
  box-shadow: color.scale($background, $lightness: -30%) 0 0px 0;
  color: #fff;
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;

  &:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  &:hover {
    background-color: color.scale($background, $lightness: 15%);
    border-color: $background;
  }

  &:focus {
    box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
    outline: none;
  }

  &:disabled {
    // TODO these are not our theme
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, 0.1);
    color: rgba(255, 255, 255, 0.8);
    cursor: default;
  }

  &:active {
    background-color: color.scale($background, $lightness: -15%);
    border-color: color.scale($background, $lightness: -30%);
    box-shadow: color.scale($background, $lightness: -45%);
  }
}

.info-insert {
  background-color: #fff5f0;
  color: #101928;
  border: 0px solid #fff5f0;
  border-radius: 100px;
  padding: 1px 6px;
}

.info-content {
  display: none;
}

.accordion {
  background-color: #fff5f0;
  color: #101928;
  border: 0px solid #fff5f0;
  border-radius: 100px;
  padding: 1px 6px;
}

.inline-container {
  display: inline-block;
  vertical-align: middle;
}

.evidence-svg {
  width: 50%;
}

.diagram-svg {
  width: 60%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .diagram-svg {
    width: 90%;
    height: auto;
  }
}

.example-svg {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .example-svg {
    width: 60%;
  }
}

.iphone-svg {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 768px) {
  .iphone-svg {
    width: 95%;
  }
}

.reliability-svg {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 768px) {
  .reliability-svg {
    width: 85%;
  }
}

.outcomes-svg {
  width: 100%;
}
.learn-svg {
  width: 100%;
}

.centered-text {
  text-align: center;
}

.scrollable-box {
  width: 95%;
  height: 140px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 3px;
  margin-top: 3px;
}

.scrollable-box ul {
  list-style-type: disc;
  padding-left: 10px;
}

.scrollable-box li {
  text-align: left;
  margin-bottom: 5px;
}
