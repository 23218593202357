#results {
  max-width: 768px;
  margin: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  & > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .outcomes li {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
}
