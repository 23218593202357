#capture-input {
  text-align: center;
  align-self: center;
  padding: 1rem;
  flex: 1;

  textarea,
  input {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: center;
    max-width: 100dvw;
  }
}

button {
  display: inline-block;
  margin-top: 10px;
  max-width: 50%;
  margin-bottom: 10px;
  align-self: center;
  text-align: center;
}
img {
  align-self: center;
  border-radius: 2px;
  margin-bottom: 10px;
  min-width: 100px;
  max-width: 40vw;
}
video,
canvas {
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 100px;
  max-width: fit-content;
  overflow: hidden;
  flex: 1;
}
